import React, { useEffect, useState } from "react";

// Material UI
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    FormControl,
    InputLabel
  } from "@material-ui/core";

const Reset = props => {

    const [form, setForm] = useState(props.data ? props.data : {status: false});

    const handleSummit = () => {
        props.onClose()
        props.onSave(form)
        setForm({ status: false });
    }
    

    return (<>
        <Dialog
        open={props.show}
        TransitionComponent={Slide}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
          <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
          <DialogContent>

          </DialogContent>
          <DialogActions>
            <Button
                color="secondary"
                variant="contained"
                onClick={props.onClose}
                color="primary"
                className="bg-danger text-white"
            >
                Cerrar
            </Button>
            <Button
                variant="contained"
                onClick={handleSummit}
                className={ "bg-success text-white" } 
                disabled= {false}
                hidden={props.readOnly}
            > Aceptar
            </Button>
          </DialogActions>
      </Dialog>
    </>)
}

export default Reset;