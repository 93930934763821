import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVert from '@material-ui/icons/MoreVert';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import TextField from "@material-ui/core/TextField";
import { set } from 'nprogress';


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headers } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headers.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? '' : ''}
                                    {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected,handleSearch } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1">
                    {numSelected} selected
          </Typography>
            ) : (
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        {props.title}
          </Typography>
                )}
            <TextField
                fullWidth
                id="outlined-text-input"
                //label="Nombre"
                label="Búsqueda"
                type="text"
                //name="name"
                onChange={handleSearch}
              />          

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('ciudad');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    console.log("dento del table la data:. ", props.data)
    const [data,setData] = React.useState(props.data);        
    const [dataFiltred,setDataFiltred] = React.useState(props.data);    
    const [searchValue,setSearchValue] = React.useState('');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = data.map(n => n.nombre);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, nombre) => {
        const selectedIndex = selected.indexOf(nombre);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, nombre);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleSearch = event => {
        setData(props.data);
        setSearchValue(event.target.value);
        var datas = data;
        //console.log("SearchValue",searchValue);
        //console.log("data",data);
        //console.log("datas",datas);
        let filteredDatas = []
        filteredDatas = datas.filter(e => {
            //console.log("Filter datas",e)
            let mathesItems = Object.values(e)
            var retVal = true;
            //console.log("retVal - 1",retVal);
            //console.log("Items",mathesItems)
            for( var i = 0; i < mathesItems.length; i++){
              var e = mathesItems[i];
              //console.log("value search",event.target.value);
              const regex = new RegExp(event.target.value, 'gi')
              if (typeof e == 'string'){
                //console.log("entro");
                retVal = e.match(regex);
                //console.log("retVal - 2",retVal);
                if(retVal != null){
                  retVal = true;
                  break;
                }
              }
            }        /*
            mathesItems.forEach(e => {
                //console.log("value search",event.target.value);
                const regex = new RegExp(event.target.value, 'gi')
                if (typeof e == 'string'){
                  //console.log("entro");
                  retVal = e.match(regex);
                  console.log("retVal - 2",retVal);
                  if(retVal != null){
                    retVal = true;
                    return;
                  }
                }
            })
            */
            //console.log("retVal - final",retVal);
            return retVal;
        })
        //console.log("filterData",filteredDatas);
        setDataFiltred(filteredDatas);
        setPage(0);
        /*
        this.setState({filterData: filteredDatas, searchValue: event.target.value})
        */
      }
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = nombre => selected.indexOf(nombre) !== -1;
    //console.log("props.data",props.data);
    //console.log("dataFiltred",dataFiltred);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, ((dataFiltred.length==0 && searchValue=='')?props.data.length:dataFiltred.length) - page * rowsPerPage);

    const {RowComponent, headers} = props

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} title = {props.title} handleSearch={handleSearch}/>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            headers={headers}
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={((dataFiltred.length==0 && searchValue=='')?props.data.length:dataFiltred.length)}
                        />
                        <TableBody>
                            {stableSort(((dataFiltred.length==0 && searchValue=='')?props.data:dataFiltred), getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.nombre);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <RowComponent
                                            tableId = {props.id}
                                            handleClick={handleClick}
                                            row={row}
                                            isItemSelected={isItemSelected}
                                            labelId={labelId}
                                            key={index}
                                            reloadData = {props.reloadData}
                                        />
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20, 40, 50]}
                    component="div"
                    count={(dataFiltred.length==0 && searchValue=='')?props.data.length:dataFiltred.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage="Filas por página"
                    labelDisplayedRows={
                        ({ from, to, count }) =>
                            `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : `más de ${to}`}`
                    }
                />
            </Paper>
        </div>
    );
}