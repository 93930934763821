import React, { useState, useEffect } from "react";
// Services
import { get } from "../../../../../services/api/http.service";

// Components
import Datatable from "../../../components/routes/tables/DataTable";
import RowTable from "./rowTable";

const List = () => {

  const [data, setData] = useState([]);
  const [showEdit, setShowEdit] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [showView, setShowView] = useState(false)
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);

  const headers = [
    { id: "name", numeric: false, disablePadding: false, label: "Nombre" },
    { id: "email", numeric: false, disablePadding: false, label: "Email" },
    { id: "balance", numeric: false, disablePadding: false, label: "Colombo Coins" },    
    { id: "actions", numeric: true, disablePadding: false, label: "Acciones" }
  ];

  const getData = async () => {
    try {
      let url = '';
      if (localStorage.getItem('admin') == '1') {
        url = "app_users/balance";
      } else {
        url = "app_users/balance/client/" + localStorage.getItem("client_id");
      }
      const tempData = await get(url);
      if(tempData.success){
        setData(tempData.rows);
      } 
    } catch (err) {
      console.log(err);
    }
  };

  const getPermissions = () => {
    let permissions = JSON.parse(localStorage.getItem('permissions'));

    if (permissions === null || permissions === undefined) {
      permissions = [];
    }
    
    let permissionTemp = permissions.filter(perm => {
      if (perm.section === 'refund') {
        return perm;
      }
    });
    console.log(permissionTemp);
    setPermissions(permissionTemp);
    setLoading(false);
  }

  useEffect(() => {
    getData();
    getPermissions();
  }, []);

  const setModalState = (name, open) => {
    console.log("dentro del set modal")
    switch(name){
      case "Create":
        console.log("dentro del create modal ", name , " ", open)
          setShowCreate(open)
        break;
      case "Edit":
        setShowEdit(open)
        break;
      case "View":
        setShowView(open)
        break;
      default:
        break;
    }
  }

  return !loading && (
    <div className="container">
      <div className="row">
        {
          !permissions[0].can_read ?
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert">
              No tienes permisos para ver esta sección
            </div>
          </div> :
          <div className="col-md-12 mr-2">
            <Datatable
              RowComponent={RowTable}
              setModal={setModalState}
              data={data}
              headers={headers}
              title="Devoluciones"
            />
          </div>
        }
      </div>
    </div>
  );
};

export default List;