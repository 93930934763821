import React from "react";
import Datatable from "../../../components/routes/tables/DataTable";
import RowTable from "./rowTable";
import fetchData from "../../../../../services/api/FetchData";
import Loader from "../../../../../components/CircularProgress";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { get, post, update, destroy } from "../../../../../services/api/http.service";

export default class List extends React.Component {
  state = {
    data: [],

    headers: [
      { id: "name", numeric: false, disablePadding: false, label: "Nombre" },
      {
        id: "secretCode",
        numeric: false,
        disablePadding: false,
        label: "Código acceso"
      },
      {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Estatus",
      },
      {
        id: "limitGroup",
        numeric: false,
        disablePadding: false,
        label: "Límite de Inscritos",
      },
      {
        id: "userCount",
        numeric: false,
        disablePadding: false,
        label: "Usuarios Inscritos",
      },
      {
        id: "actions",
        numeric: true,
        disablePadding: false,
        label: "Acciones",
      }
    ],
  };

  setData = async (route, dataName) => {
    try {
      const response = await get(route + "/client/" + localStorage.getItem("client_id"));
      if (response.success) {
        //const data = response.rows.filter(({ active }) => !!active);
        const data = response.rows;

        //data.trademark_name = this.state.trademarks.find(row => row.id == data.trademark_id).
        /*for(let iCont in data){
            //data[iCont]
            data[iCont].trademarks_name = this.state.trademarks.find(row => row.id == data[iCont].trademarks_id).name
            data[iCont].mission_types_name = this.state.mission_types.find(row => row.id == data[iCont].mission_types_id).name
            data[iCont].status_name = this.state.status.find(row => row.id == data[iCont].statusId).name
        }*/
        console.log("GRUPOS:");
        console.log(data);

        this.setState({
          ...this.state,
          [dataName]: data,
          dataFiltred: data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidMount() {
    await this.setData(`groups`, "data");
  }

  reloadData = () => this.props.history.push("/app/dashboard/");

  render() {
    let permissions = JSON.parse(localStorage.getItem('permissions'));

    if (permissions === null || permissions === undefined) {
      permissions = [];
    }
    
    let permission = permissions.filter(perm => {
      if (perm.section === 'group') {
        return perm;
      }
    });

    if (permission.lenght === 0 || permission[0].can_read === false) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-danger" role="alert">
                No tienes permisos para ver esta sección
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3 mt-3">
            {
              permission[0].can_write &&
              <Button>
                <Link className="btn btn-primary text-white" to={`/app/groups/create`}>
                  Crear Grupo
                </Link>
              </Button>
            }
          </div>
          <div className="col-md-9">
          </div>
          <div className="col-md-12  mr-2">
            <Datatable
              RowComponent={RowTable}
              data={this.state.data}
              headers={this.state.headers}
              reloadData={this.reloadData}
              title="Grupos"
            />
          </div>
        </div>
      </div>
    );
  }
}
