import {useDispatch} from 'react-redux'
import {userSignOut} from 'actions/Auth';
import swal from 'sweetalert';
import {
  auth,
} from "../../firebase/firebase";
import {
  useHistory,
  Redirect
} from "react-router-dom";

import React, {useEffect, useState} from 'react';

const fetchData = async (route, params, formData=false) => {

    let bearer_token;
    if(typeof params.headers != "undefined" && params.headers != null &&
      typeof params.headers.Authorization != "undefined" && params.headers.Authorization != null ){
        bearer_token = params.headers.Authorization
    } else {
      bearer_token = `bearer ${localStorage.getItem('tokenAPI')}`
    }
   
    params.headers = {
      ...params.headers,
      //Authorization: `bearer ${storageManage.getToken()}`
      //"Content-Type": "application/json",
      "Authorization": bearer_token
    }

    if(!formData){
      params.headers = {
        ...params.headers,
        "Content-Type": "application/json",
      }
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROUTE}/${route}`, params)
      if (response.status == 403) {
        /*console.log("*************************El estatus de la petición es: ", response.status)
        userSignOut()
        localStorage.removeItem('tokenAPI');
        await history.push("/signin");*/
        //window.location.href = "close"
        
        
        //swal("Tu sesión ha finalizado", "Inicia sesión nuevamente", "info")
        swal({
          title: "Tu sesión ha finalizado",
          text: "Inicia sesión nuevamente",
          icon: "info",
          confirmButtonColor: "#ffb133",
        })
        .then(() => {
          // TO-DO: Fix Redux on this file
          auth.signOut()
          localStorage.removeItem('tokenAPI')
          localStorage.removeItem('user_id')
          localStorage.removeItem("ID")
          localStorage.removeItem("username")
          window.location.href = "/signin"
        })
        
        console.log("********* Se debería cerrar sesión **************")
      }
      return response
    } catch (error) {
      console.log("Error: ", error)
      return { success: false, message: "Ha ocurrido un problema intentando acceder al servidor" }
    }
  }
  
export default fetchData