import React from 'react'
import Form from '../Components/form'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import fetchData from "../../../../../services/api/FetchData"
import swal from 'sweetalert';
import { get, post, update, destroy } from "../../../../../services/api/http.service";

export default class Create extends React.Component {

    state = {
        social_networks: [],
        trademarks: [],
        mission_types: [],
        rewards: [],
        status: [],
        fan_pages: []
    }

    handleSubmit = async (values, groups, allGroups, withoutGroups, qr_image, question, question_details, coinsByQuestions) => {
        try{
            //poner time en 0 del datetime
            let data = Object.assign({},values)     
            //console.log("Mission")
            if(values.mission_types_id === 9) {
                data.is_social_network = false;
            } else {
                data.is_social_network = true;
            }
            data.qr_image = qr_image
            data.client_id = localStorage.getItem("client_id")
            console.log(data)
            //data.date_transaction = values.date_transaction ? (new Date(values.date_transaction)).setHours(0,0,0) : values.date_transaction
            //data.deadline = values.deadline ? (new Date(values.deadline)).setHours(23,59,59) : values.deadline

            data.groups = groups
            //data.isWithoutGroup = withoutGroups ? 1 : (allGroups ? withoutGroups : )

            if(data.social_networks_id !== 1) {
                data.fanPages_id = null;
            }

            if(withoutGroups){
                data.isWithoutGroup = true
            } else {
                let findedAnyGroup = groups.filter(row => row.isSelectedInMission == true)
                if (allGroups || (typeof findedAnyGroup != "undefined" && findedAnyGroup != null && findedAnyGroup.length > 0) ){
                    data.isWithoutGroup = false
                } else {
                    data.isWithoutGroup = null
                }
            }
            data.question = question 
            data.question_details = question_details
            data.coinsByQuestions = coinsByQuestions

            const response = await post("missions", data)
            console.log("\n\n\n", response)
            if (!response.success) {
                swal({
                    title: "Error",
                    text: "No se ha podido registrar la Misión",
                    icon: "error",
                    confirmButtonColor: "#ffb133",
                })
                return
            }
            swal({
                title: "Misión registrada",
                text: "La misión se ha registrado correctamente",
                icon: "success",
                confirmButtonColor: "#ffb133",
            })
            .then(() => window.location.href = "/app/missions/"/*this.props.history.push(`/app/missions/`)*/)
        }catch(err){
            swal({
                title: "Error",
                text: err,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
        }
    }

    handleCancel = () => window.location.href = "/app/missions/"//this.props.history.push("/app/missions")

    async componentDidMount() {
        await this.getSocialNetworks()
        await this.getTrademarks()
        await this.getRewards()
        await this.getMissionTypes()
        await this.getStatus()
        await this.getFanPages()
    }

    async getFanPages() {
        try {
            let response = await get("fan_pages")
            if(response.success){
                this.setState({
                    ...this.state,
                    fan_pages: response.rows.filter(fan_page => fan_page.status === true) // solo fan_pages activas
                });
            } 
        } catch (err) {
            console.log("Error: ", err)
        }
    }

    async getSocialNetworks(){
        try {
            let response = await get("social_networks")
            if(response.success){
                this.setState({
                    ...this.state,
                    social_networks: response.rows.filter(trademark => trademark.status === true) // solo redes sociales activas
                });
            }
        } catch (err) {
            console.log("Error: ", err);
        }
    }

    async getTrademarks(){
        try{
            let response = await get("trademarks")
            if(response.success){
                this.setState({
                    ...this.state,
                    trademarks: response.rows.filter(trademark => trademark.status === true) // solo marcas activas
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }

    async getRewards() {
        try{
            let response = await get("rewards");
            if(response.success){
                this.setState({
                    ...this.state,
                    rewards: response.rows
                });
            }
        } catch(err) {
            console.log("Error: ", err)
        }
    }

    async getMissionTypes(){
        try{
            let response = await get("mission_types")
            if(response.success){
                this.setState({
                    ...this.state,
                    mission_types: response.rows
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }

    async getStatus(){
        try{
            let response = await get("status")
            if(response.success){
                this.setState({
                    ...this.state,
                    status: response.rows.filter(status => status.status_typeId === 4) // Solo estatus de misiones 
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }
    
    render() {        
        return (
            <Card className="container-fluid p-4 pl-5">
                <div className='d-flex m-4 align-middle'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path d="M42 6V48H6V6H18C18 5.17188 18.1562 4.39844 18.4688 3.67969C18.7812 2.96094 19.2109 2.32031 19.7578 1.75781C20.3047 1.19531 20.9375 0.765625 21.6562 0.46875C22.375 0.171875 23.1562 0.015625 24 0C24.8281 0 25.6016 0.15625 26.3203 0.46875C27.0391 0.78125 27.6797 1.21094 28.2422 1.75781C28.8047 2.30469 29.2344 2.9375 29.5312 3.65625C29.8281 4.375 29.9844 5.15625 30 6H42ZM15 9V12H33V9H27V6C27 5.57812 26.9219 5.1875 26.7656 4.82812C26.6094 4.46875 26.3984 4.15625 26.1328 3.89062C25.8672 3.625 25.5469 3.40625 25.1719 3.23438C24.7969 3.0625 24.4062 2.98438 24 3C23.5781 3 23.1875 3.07812 22.8281 3.23438C22.4688 3.39062 22.1562 3.60156 21.8906 3.86719C21.625 4.13281 21.4062 4.45312 21.2344 4.82812C21.0625 5.20312 20.9844 5.59375 21 6V9H15ZM39 9H36V15H12V9H9V45H39V9ZM29.5078 16.8516C30.1641 16.8516 30.7734 16.9688 31.3359 17.2031C31.8984 17.4375 32.3984 17.7656 32.8359 18.1875C33.2734 18.6094 33.6016 19.1016 33.8203 19.6641C34.0391 20.2266 34.1641 20.8359 34.1953 21.4922C34.1953 22.1016 34.0781 22.6953 33.8438 23.2734C33.6094 23.8516 33.2734 24.3594 32.8359 24.7969L20.5078 37.2422L12.1172 38.8359L13.875 30.6094L26.2031 18.1875C26.6406 17.75 27.1484 17.4219 27.7266 17.2031C28.3047 16.9844 28.8984 16.8672 29.5078 16.8516ZM30.7031 22.6875C31.0312 22.3281 31.1953 21.9297 31.1953 21.4922C31.1953 21.0078 31.0391 20.6172 30.7266 20.3203C30.4141 20.0234 30.0078 19.8672 29.5078 19.8516C29.2891 19.8516 29.0781 19.8828 28.875 19.9453C28.6719 20.0078 28.4922 20.125 28.3359 20.2969L16.6406 32.0859L16.0078 35.0391L19.0312 34.4531L30.7031 22.6875Z" fill="#FF4F00"/>
                    </svg>
                    <span className='missionPrimaryTitle'>Crea una nueva misión</span>
                </div>
                <div className='ml-5 mr-5'>
                    <span className='normalText'>
                        Este es tu espacio para dar vida a nuevas tareas y desafíos que motivarán a tus usuarios. Configura tu misión a continuación.
                    </span>

                    <Form onCancel={this.handleCancel} onSubmit={this.handleSubmit}  handleSubmit={this.handleSubmit} 
                        trademarks={this.state.trademarks} rewards={this.state.rewards} fan_pages={this.state.fan_pages}
                        mission_types={this.state.mission_types} status={this.state.status} social_networks={this.state.social_networks} 
                        isSocialNetworkReadOnly={false}
                        />
                </div>
            </Card>
        )
    }
}