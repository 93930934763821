import React from 'react'
import Form from '../Components/form'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import fetchData from "../../../../../services/api/FetchData"
import swal from 'sweetalert';
import { get, post, update, destroy } from "../../../../../services/api/http.service";

export default class Create extends React.Component {

    state = {
        notification_types: [],
        groups: []
    }

    /*
    handleSubmit = async (values, groups) => {
        console.log("enviado")
        try{
            //poner time en 0 del datetime
            //console.log("los values jajajaja: ", values, " y los grupos: ", groups)
            let data = Object.assign({},values)
            //console.log("Mission")
            // console.log("JJJJJJJ",data)
            // console.log("CCCCCCCC",groups)
            data.groups_id = groups.find(row => row.isSelectedInNotification).id

            const response = await post("notifications", data)
            console.log("\n\n\n", response)
            if (!response.success) {
                swal("Error", response.message, "error")
                return
            }
            swal("Grupo registrado", 
            response.message, 
            "success").then(() => this.props.history.push(`/app/notifications/`))
        }catch(err){
            swal("Error", err, "error")
        }
    }
    */

    handleSubmit = async (values, groups) => {
        console.log("enviado")
        try{
            values.groups_id = groups;
            let data = Object.assign({},values)
            data.client_id = localStorage.getItem("client_id")
            const response = await fetch('https://mqpmqs455l6kdgo7ttb36qvhuu0qexgq.lambda-url.us-east-1.on.aws', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            console.log("\n\n\n", response);
            const responseParsed = await response.json();

            if (!responseParsed.success) {
                swal({
                    title: "Error",
                    text: responseParsed.message,
                    icon: "error",
                    confirmButtonColor: "#ffb133",
                })
                return
            }
            swal({
                title: "Notificación registrada",
                text: responseParsed.message,
                icon: "success",
                confirmButtonColor: "#ffb133",
            })
            .then(() =>  window.location.href = "/app/notifications/"/*this.props.history.push(`/app/notifications/`)*/)
        }catch(err){
            swal({
                title: "Error",
                text: err,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
        }
    }    

    async getNotificationTypes(){
        try{
            let response = await get("notification_types")
            if(response.success){
                //trademarks = response.rows
                this.setState({
                    ...this.state,
                    notification_types: response.rows
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }

    async getGroups(){
        try{
            let response = await get("groups/client/" + localStorage.getItem("client_id"))
            if(response.success){
                //trademarks = response.rows
                this.setState({
                    ...this.state,
                    groups: response.rows.filter(group => group.status === true)
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }

    handleCancel = () => this.props.history.push("/app/notifications")

    async componentDidMount() {
        await this.getNotificationTypes()
        await this.getGroups()
    }

    render() {   
        return (
            <Card className="container-fluid p-4 pl-5">
                <h1>Registrar notificación</h1>
                <Divider className="mb-4" />
                <Card className="col-12 p-4 pl-5 mr-4">
                    <Form onCancel={this.handleCancel} onSubmit={this.handleSubmit}  handleSubmit={this.handleSubmit}
                        notification_types= {this.state.notification_types} 
                        groups={this.state.groups}
                    />
                </Card>
            </Card>
        )
    }
}