import React from "react";
//import Datatable from '../../../../../components/Datatable'
//import Datatable from '../../../../routes/table/routes/data/Components/DataTable'
import Datatable from "../../../components/routes/tables/DataTable";
import RowTable from "./rowTable";
import fetchData from "../../../../../services/api/FetchData";
//import Loader from "../../../../../components/Loader"
import Loader from "../../../../../components/CircularProgress";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { get, post, update, destroy } from "../../../../../services/api/http.service";
import { load } from "dotenv";

export default class List extends React.Component {
  state = {
    data: [],

    headers: [
      { id: "Name", numeric: false, disablePadding: false, label: "Título" },
      { id: "message", numeric: false, disablePadding: false, label: "Texto" },
      // {
      //   id: "notification_type",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Tipo de notificación",
      // },
      {
        id: "shipping_date",
        numeric: false,
        disablePadding: false,
        label: "Fecha de envío",
      },
      {
        id: "actions",
        numeric: true,
        disablePadding: false,
        label: "Acciones",
      },
    ],
  };

  setData = async (route, dataName) => {
    try {
      let url = '';
      if (localStorage.getItem('admin') == '1') {
        url = route;
      } else {
        url = route + '/client/' + localStorage.getItem('client_id');
      }
      const response = await get(url);
      if (response.success) {
        const data = response.rows;
        this.setState({
            ...this.state,
            [dataName]: response.rows
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidMount() {
    await this.setData(`notifications`, "data");
  }

  reloadData = () => this.props.history.push("/app/notifications");

  render() {
    let permissions = JSON.parse(localStorage.getItem('permissions'));

    if (permissions === null || permissions === undefined) {
      permissions = [];
    }
    
    let permission = permissions.filter(perm => {
      if (perm.section === 'notification') {
        return perm;
      }
    });

    if (permission.lenght === 0 || permission[0].can_read === false) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-danger" role="alert">
                No tienes permisos para ver esta sección
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3 mt-3">
            {
              permission[0].can_write &&
              <Button>
                <Link className="btn btn-primary text-white" to={`/app/notifications/create`}>
                  Crear notificación
                </Link>
              </Button>
            }
          </div>
          <div className="col-md-9">
          </div>
          <div className="col-md-12  mr-2">
            <Datatable
              RowComponent={RowTable}
              data={this.state.data}
              headers={this.state.headers}
              reloadData={this.reloadData}
              title="Notificaciones"
            />
          </div>
        </div>
      </div>
    );
  }
}
