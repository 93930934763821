import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Formik } from 'formik'
import * as Yup from 'yup'
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from '@material-ui/core/Checkbox';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { FormGroup, FormControlLabel } from '@material-ui/core';
import { get } from "../../../../../services/api/http.service";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import "../../../../../App.css"

import SelectReact from 'react-select';
import makeAnimated from 'react-select/animated';
const requiredField = "Campo Requerido"
const positiveNumber = "Número debe ser positivo"

let todayDate = new Date()
const animatedComponents = makeAnimated();

class DataDisplayer extends Component {

  constructor(props) {
    super(props)
    if (props.reward) {
      const { reward } = props
      this.state = { reward }
      this.state = {
        reward : {
          ...reward
        },
        rewards_types: [],
        groups: [],
        rewards_material: {quantity: 0},
      }
    } else {
      this.state = {
        reward: {
          name: "",
          description: "",
          url_target: "",
          status: false,
          rewards_types_id: 0,
          start_date: todayDate,
          deadline: todayDate,
          isForUsersWithoutGroup: true,
          noGroupsSelectedError: false,
          amount: 0.00,
          //to control check
          //isWithGroups: false
        },
        rewards_types: [],
        groups: [],
        rewards_material: {quantity: 0},
      }
    }   
  }

  async componentDidMount() {
    await this.getRewardsTypes();
    await this.getGroups()
  }

  getRewardsTypes = async () => {
    try {
      const response = await get("rewards/types/list")
      if (response.success) {
        const data = response.rows;
        this.setState({
          ...this.state,
          ["rewards_types"]: data
        });
      }
    } catch (error) {
      console.log(" Error: ", error);
    }
  };

  getGroups = async () => {
    try {
      let rewardId = typeof this.state.reward.id != "undefined" && this.state.reward.id != null ? this.state.reward.id : 0
      const response = await get("groups/reward/" + rewardId)
      if (response.success) {
        //const data = response.rows.filter(({ active }) => !!active);
        let isAnyGroupSelected = response.rows.filter(row => row.isSelectedInReward == true)
        const data = response.rows
        console.log("la data ", data)
        this.setState({
          ...this.state,
          ["groups"]: data
        });

        this.setState(prevState => {
          let reward = Object.assign({}, prevState.reward);  
          reward.isForUsersWithoutGroup = isAnyGroupSelected.length > 0 ? false : true                            
          return { reward };                     
        })
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = (values, actions) => { 
    console.log('DENTRO')
    console.log(this.state.groups)
    this.setState({
      ...this.state,
      noGroupsSelectedError: false
    })

    if(this.state.reward.isForUsersWithoutGroup === false) {
      let isAnyGroupSelected = this.state.groups.filter(row => row.isSelectedInReward == true).length > 0 ? true : false;

      if(!isAnyGroupSelected) {
        console.log('Error: selecciona grupos');
        this.setState({
          ...this.state,
          noGroupsSelectedError: true,
        });
        actions.setSubmitting(false);
        return;
      }
    }

    this.props.handleSubmit(values, this.state.groups)
    actions.setSubmitting(false)
  }

  handleCancel = (values) => {
    this.props.onCancel(values)
  }

  handleRadioButtonChange = event => {
    this.setState(prevState => {
      let reward = Object.assign({}, prevState.reward);  // creating copy of state variable jasper
      reward.isForUsersWithoutGroup = !reward.isForUsersWithoutGroup ;                     // update the name property, assign a new value                 
      return { reward };                                 // return new object jasper object
    })

    console.log("baia baia ", this.state.reward)
  }

  handleCheckGroupSelect = event => {
    console.log("el che evento ", event)
    let flag = false
    let eventGroups = event
    if(typeof event == "undefined" || event == null){
      eventGroups = []
    }
    let id;
    if(eventGroups.length > 0){
      id = eventGroups[eventGroups.length-1].id
    }
    let tempGroups = this.state.groups
    for(let group of tempGroups){
      if(typeof eventGroups.find(row => row.id == group.id) != "undefined"){
        flag = true
      }
      group.isSelectedInReward = typeof eventGroups.find(row => row.id == group.id) != "undefined" ? true : false
    }
    this.setState({
      ...this.state,
      ["groups"]: tempGroups
    });

    this.setState(prevState => {
      let reward = Object.assign({}, prevState.reward);  
      reward.isForUsersWithoutGroup = !flag ;                             
      return { reward };                     
    })

    console.log("los grupos: ", this.state.groups)
  }

  render(){
    const { reward } = this.state

    return (
      <Formik
        initialValues={reward}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required(requiredField),
          rewards_types_id: Yup.number()
            .required(requiredField)
            .positive(positiveNumber),
          description: Yup.string()
            .required(requiredField),
          start_date: Yup.date()
            .required(requiredField),
          deadline: Yup.date()
            .min(Yup.ref('start_date'), "Fecha Fin vigencia no puede ser menor a Fecha Inicio vigencia")
            .required(requiredField),
          amount: Yup.number()
            .required(requiredField)
            .positive(positiveNumber),
          url_target: Yup.string()
            .required(requiredField),
          
          // isForUsersWithoutGroup: Yup.boolean()
          //   .required(requiredField),
          // isWithGroups: Yup.boolean()
          //   .required(requiredField)
        })}
      >
                
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = props     

          return (
            <form className="container-fluid" autoComplete='off' onSubmit={handleSubmit}>              
              <div className="row mb-md-3">
                <div className="col-lg-6 col-sm-6 col-12">
                  <TextField
                    fullWidth
                    id="outlined-text-input"
                    label="Nombre de la recompensa"
                    type="text"
                    name="name"
                    inputProps={{ maxLength: 128, readOnly: this.props.readOnly }}
                    onBlur={handleBlur}
                    error={errors.name && touched.name}
                    helperText={touched.name && (errors.name)}
                    className={errors.name && touched.name && "error"}
                    value={values.name}
                    onChange={handleChange}
                  />
                 </div> 
                 <div className="col-lg-6 col-sm-6 col-12">
                 {<FormControl fullWidth error={ Boolean(touched.rewards_types_id && (errors.rewards_types_id)) }>
                      <InputLabel>Tipo de recompensa</InputLabel>
                      <Select
                        fullWidth
                        id="outlined-text-input"
                        label="Tipo de recompensa"
                        type="text"
                        name="rewards_types_id"
                        inputProps={{ maxLength: 128, readOnly: this.props.readOnly || values.statusId == 2}}
                        onBlur={handleBlur}
                        error={errors.rewards_types_id && touched.rewards_types_id}
                        helperText={touched.rewards_types_id && (errors.rewards_types_id)}
                        className={errors.rewards_types_id && touched.rewards_types_id && "error"}
                        value={values.rewards_types_id}
                        onChange={handleChange}
                      >
                      {this.state.rewards_types ? this.state.rewards_types.map( (type) => {
                        return (<MenuItem value={type.id}> {type.name} </MenuItem>)
                      }) : null
                      }
                    </Select>
                    {
                      touched.rewards_types_id && (errors.rewards_types_id) && <FormHelperText>{ errors.rewards_types_id }</FormHelperText>
                    }
                  </FormControl>
                  }
                </div>
              </div>


              <div className="row mb-md-3">
                <div className="col-lg-6 col-sm-6 col-12">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      format="dd/MM/yyyy"
                      margin="normal"
                      disablePast
                      readOnly={this.props.readOnly}
                      onBlur={handleBlur}
                      error={errors.start_date && touched.start_date}
                      helperText={touched.start_date && (errors.start_date)}
                      className={errors.start_date && touched.start_date && "error"}  
                      label="Fecha Inicio de vigencia"
                      name="start_date"
                      value={values.start_date}
                      InputProps={{ readOnly: this.props.readOnly }}
                      onChange={date => {
                        date = date ? new Date(date) : date
                        values.start_date = date
                        this.forceUpdate()
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      format="dd/MM/yyyy"
                      margin="normal"
                      disablePast
                      readOnly={this.props.readOnly}
                      onBlur={handleBlur}
                      error={errors.deadline && touched.deadline}
                      helperText={touched.deadline && (errors.deadline)}
                      className={errors.deadline && touched.deadline && "error"}  
                      label="Fecha Fin de vigencia"
                      name="deadline"
                      InputProps={{ readOnly: this.props.readOnly }}
                      value={values.deadline}
                      minDate={values.start_date || undefined }
                      onChange={date => {
                        values.deadline = date
                        this.forceUpdate()
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    </MuiPickersUtilsProvider>
                </div>  
              </div>

              <div className="row mb-md-3">
                <div className="col-lg-6 col-sm-6 col-12">
                  <FormControl fullWidth>
                    <TextField
                        fullWidth
                        id="outlined-text-input"
                        label="Coins"
                        type="number"
                        name="amount"
                        positiveNumber
                        positive
                        inputProps={{ readOnly: this.props.readOnly }}
                        onBlur={handleBlur}
                        error={errors.amount && touched.amount}
                        helperText={touched.amount && (errors.amount)}
                        className={errors.amount && touched.amount && "error"}
                        value={values.amount}
                        onChange={handleChange}
                      />                  
                  </FormControl>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <TextField
                        fullWidth
                        id="outlined-text-input"
                        //label="Nombre"
                        label="URL de redención"
                        type="text"
                        //name="name"
                        name="url_target"
                        inputProps={{ maxLength: 128, readOnly: this.props.readOnly }}
                        onBlur={handleBlur}
                        error={errors.url_target && touched.url_target}
                        helperText={touched.url_target && (errors.url_target)}
                        className={errors.url_target && touched.url_target && "error"}
                        value={values.url_target}
                        onChange={handleChange}
                      />
                </div>
              </div>

{/* 
              {values.rewards_types_id == 2
              ? 
              <div>
                <TextField
                  fullWidth
                  id="outlined-text-input"
                  label="Número de recompensas"
                  type="number"
                  name="rewards_material_number"
                  inputProps={{ readOnly: this.props.readOnly }}
                  value={this.state.rewards_material_number}
                  positiveNumber
                  positive
                  //onChange={handleChange}
                />

                <FormControl fullWidth>
                  {!values.description && (
                    <InputLabel className="pl-2" error={errors.description && touched.description}>Instrucciones para obtener la recompensa</InputLabel>
                  )}
                  <TextareaAutosize
                    readOnly={this.props.readOnly}
                    value={values.description}
                    name="description"
                    type="text"
                    rowsMin={4}
                    maxLength={512}
                    onChange={handleChange}
                  />
                  {errors.description && touched.description && (<FormHelperText error={errors.description && touched.description}>{errors.description}</FormHelperText>)}
                </FormControl>  
              </div>
              :null}                        */}

              {this.state.noGroupsSelectedError && (<FormHelperText error={this.state.noGroupsSelectedError}>Selecciona grupos</FormHelperText>)}
              <FormGroup row>
                <FormControlLabel
                  control={
                  <Checkbox color="primary"
                    checked={this.state.reward.isForUsersWithoutGroup ? true : false}
                    onChange={this.handleRadioButtonChange}
                    value={!this.state.reward.isForUsersWithoutGroup}
                  />
                  }
                label={"La recompensa es para usuarios sin grupo"}
                disabled={this.props.readOnly}
                />
                </FormGroup>

                <FormControlLabel
                  control={
                    <Checkbox color="primary"
                      checked={this.state.reward.isForUsersWithoutGroup ? false : true}
                      onChange={this.handleRadioButtonChange}
                      value={!this.state.reward.isForUsersWithoutGroup}
                   />     
                  }
                label={"La recompensa es para usuarios con grupo"}
                disabled={this.props.readOnly}
              />

              {!this.state.reward.isForUsersWithoutGroup
              ? <SelectReact
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={(this.state.groups.map( group => {
                    if(group.isSelectedInReward){
                      return {value: group.name, label: group.name, id: group.id}
                    }
                  }))}
                  placeholder="Seleccionar grupos"
                  isMulti
                  onChange={this.handleCheckGroupSelect}
                  isDisabled={this.props.readOnly}
                  options={this.state.groups.map( group => {
                    //el componente elimina de las opciones el grupo escogido en automatico
                      return {value: group.name, label: group.name, id: group.id}
                  })}
                />
              : null}

              <div className="row align-items-end mb-4">                                    
                <FormControl fullWidth>
                  {!values.description && (
                    <InputLabel className="pl-2" error={errors.description && touched.description}>Descripción </InputLabel>
                  )}
                  <TextareaAutosize
                    readOnly={this.props.readOnly}
                    value={values.description}
                    name="description"
                    type="text"
                    rowsMin={4}
                    maxLength={512}
                    onChange={handleChange}
                  />
                  {errors.description && touched.description && (<FormHelperText error={errors.description && touched.description}>{errors.description}</FormHelperText>)}
                </FormControl>                                  
              </div>

              
              {this.props.readOnly &&
                <div className="row justify-content-center mt-4 pl-4">
                  <Button className='btn mr-3' style={{ backgroundColor: '#D2181F', color: 'white' }} onClick={() => { this.handleCancel(values) }} >Regresar</Button>
                </div>}
              {(!this.props.readOnly && (<div className="row justify-content-center mt-4 pl-4">
                <Button disabled={isSubmitting} className='btn mr-3' style={{ backgroundColor: '#D2181F', color: 'white' }} onClick={() => { this.handleCancel(values) }} >
                  Cancelar
                                    </Button>
                <Button disabled={isSubmitting} className='btn' style={{ backgroundColor: '#4E6E58', color: 'white' }} type="submit" >
                  {isSubmitting && <span className="spinner-border spinner-border-sm"></span>}
                                    Continuar
                                    </Button>
              </div>))}
            </form>
          )
        }}
      </Formik>
    );
  }
}

export default DataDisplayer;