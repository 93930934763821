import React from "react";
//import Datatable from '../../../../../components/Datatable'
//import Datatable from '../../../../routes/table/routes/data/Components/DataTable'
import Datatable from "../../../components/routes/tables/DataTable";
import RowTable from "./rowTable";
import fetchData from "../../../../../services/api/FetchData";
//import Loader from "../../../../../components/Loader"
import Loader from "../../../../../components/CircularProgress";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { get, post, update, destroy } from "../../../../../services/api/http.service";

export default class List extends React.Component {
  state = {
    data: [],
    headers: [
      { id: "id", numeric: false, disablePadding: false, label: "ID" },
      { id: "name", numeric: false, disablePadding: false, label: "Título" },
      {
        id: "description",
        numeric: false,
        disablePadding: false,
        label: "Descripción",
      },
      {
        id: "amount",
        numeric: false,
        disablePadding: false,
        label: "Coins"
      },
      {
        id: "actions",
        numeric: true,
        disablePadding: false,
        label: "Acciones",
      },
    ],
    showModal: false
  };

  setData = async (route, dataName) => {
    try {
      const response = await get(route)
      if (response.success) {
        this.setState({
          ...this.state,
          [dataName]: response.rows,
          dataFiltred: response.rows,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidMount() {
    await this.setData(`rewards`, "data");
  }

  reloadData = () => this.props.history.push("/app/rewards/");

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3 mt-3">
            <Button>
              <Link className="btn btn-primary " to={`/app/rewards/create`}>
                Crear recompensa
              </Link>
            </Button>
          </div>
          <div className="col-md-9">
          </div>
          <div className="col-md-12  mr-2">
            <Datatable
              RowComponent={RowTable}
              data={this.state.data}
              headers={this.state.headers}
              reloadData={this.reloadData}
              title="Recompensas"
            />
          </div>
        </div>
      </div>
    );
  }
}
