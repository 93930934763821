import React, { useState, useEffect } from "react";

// Material UI
import { Button } from "@material-ui/core";

// Services
import { get, post, update, destroy } from "../../../../../services/api/http.service";
import CreateMissionTypeForm from "../Components/formMissionType"


// Components
import Datatable from "../../../components/routes/tables/DataTable";
// import RowTable from "./RowTable";

const List = () => {
    const [dataMissionType, setDataMissionType] = useState([]);
    const [showModalUser, setShowModalUser] = useState(false);

    const headers = [
      { id: "name", numeric: false, disablePadding: false, label: "Nombre" },
      { id: "status", numeric: false, disablePadding: false, label: "Estatus" }
    ];

    useEffect(() => {
        const getDataEffect = async () => {
          try {
            const tempData = await get("mission_types");
            //console.log("users", tempData.rows);
            //console.log("if ", tempData.success);
            if(tempData.success){
              let rows = tempData.rows;
              setDataMissionType(rows);
            } else {
              //console.log("No se encontraron usuarios")
            }
          } catch (err) {
            //console.log(err);
          }
        };
        getDataEffect();
    }, []);

    return (
        <>
        <div className="container">
        <div className="row">
            <div className="col-md-3 mb-3 mt-3">
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>{
                        setShowModalUser(true);
                    }}
                    >Crear Tipo de Misión</Button>
                    </div>
                <div className="col-md-12 mr-2">
                {/* <Datatable
                    RowComponent={RowTable}
                    data={dataUser}
                    headers={headers}
                    title="Administración de Usuarios"
                /> */}
                </div>
            </div>
        </div>
        <p>{dataMissionType.length}</p>
        <CreateMissionTypeForm 
            show={showModalUser}
            onClose={() => {
            setShowModalUser(false)
            }}
        />
        </>
    );
    
};

export default List;