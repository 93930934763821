import React, { useState } from "react";

// Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  FormControl,
  TextareaAutosize,
  InputLabel
} from "@material-ui/core";

const Form = (props) => {

  const [form, setForm] = useState(props.data ? props.data : {status: false});

  const createData = (e) => {

    setForm({
      ...form,
      [e.target.name]: e.target.value 
    });
    console.log("Datos del form ", form)
  };

  const handleSummit = () => {
    props.onClose()
    props.onSave(form)
    setForm({ status: false });
  }


  return (
    <>
      <Dialog
        open={props.show}
        TransitionComponent={Slide}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            size="small"
            margin="normal"
            id="name"
            label="Colombo Coins"
            type="number"
            fullWidth
            name="amount"
            onChange={createData}
            InputProps={{ readOnly: props.readOnly }}
          />

          <FormControl fullWidth>
            {
              typeof form.description == "undefined" && <InputLabel className="pl-2">Descripción </InputLabel>
            }
            <TextareaAutosize
              size="small"
              margin="normal"
              name="description"
              type="text"
              rowsMin={4}
              maxLength={512}
              onChange={createData}
              InputProps={{ readOnly: props.readOnly }}
            />
          </FormControl>                          

        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={props.onClose}
            color="primary"
            className="bg-danger text-white"
          >
            Cerrar
          </Button>
          <Button
              variant="contained"
              onClick={handleSummit}
              className={typeof form.amount == "undefined" || typeof form.description == "undefined" ? "bg-success.disabled text-white" : "bg-success text-white" } 
              disabled= {typeof form.amount == "undefined" || typeof form.description == "undefined" ? true : ( form.amount <= 0 || form.description.length <= 0 ) }
              hidden={props.readOnly }
          > Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Form;
