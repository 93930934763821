import React, { useState } from "react";
import {
  Menu,
  MenuItem,
} from "@material-ui/core";
import swal from "sweetalert";
import { post } from "../../../../../services/api/http.service";
import Form from "../components/form"
import Reset from "../components/reset"
import { decipherData } from "services/api/Security";
const jwt = require("jsonwebtoken");

const Options = (props) => {
  const { menuState, anchorEl, handleRequestClose, data} = props;
  const [showAdd, setShowAdd] = useState(false)
  const [showReset, setShowReset] = useState(false)

  const balanceAddition = async (form, selectedgroup) => {
    console.log("Register params: ", form);
    let userid = form.id;
    let group_id = selectedgroup;
    const body = {
      user_id: userid, 
      groups_id: group_id
    };

    try{
          let response = await post('app_users/change_group', body)
          if(response.success){
            swal({
              title: "cambio de grupo exitoso",
              text: response.message,
              icon: "success",
              confirmButtonColor: "#ffb133",
            })
            .then(() => {
              window.location.href = window.location.href;
            });
          } else {
            swal({
              title: "Error",
              text: `Ups, hubo un problema \n ${response.message}`,
              icon: "error",
              confirmButtonColor: "#ffb133",
            })
          }

    }catch(err){
      swal({
        title: "Error",
        text: `Ups, hubo un problema \n ${err}`,
        icon: "error",
        confirmButtonColor: "#ffb133",
      })
    }
  };

  const resetAppUser = async (form) => {
    let userid = form.id;
    const body =  {
      app_user_id: userid
    }
    try {
      let tokenAPI = localStorage.getItem('tokenAPI');
      jwt.verify(tokenAPI , process.env.REACT_APP_JWT_SECRET, async (err, data) => { 
          if(err){
            console.log("Falla: " + err)
            swal({
              title: "Error",
              text: `Vuelve a iniciar sesión por favor.`,
              icon: "error",
              confirmButtonColor: "#ffb133",
            })
            return
          } else {
            let userData = await decipherData(data.data)  
            if(typeof userData.id == "undefined" || userData.id === null){
              swal({
                title: "Error",
                text: `No pudimos encontrar tu id, vuelve a iniciar sesión por favor.`,
                icon: "error",
                confirmButtonColor: "#ffb133",
              })
              return
            }
            body.user_id = parseInt(userData.id)
            let response = await post('app_users/reset', body)
            if(response.success) {
              swal({
                title: "reset de usuario exitoso",
                text: `reset exitoso`,
                icon: "success",
                confirmButtonColor: "#ffb133",
              })
              .then(() => {
                window.location.href = window.location.href;
              });
            } else {
              swal({
                title: "Error",
                text: `Ups, hubo un problema \n ${response.message}`,
                icon: "error",
                confirmButtonColor: "#ffb133",
              })
            }
          }
      })
    } catch(err) {
      swal({
        title: "Error",
        text: `Ups, hubo un problema \n ${err}`,
        icon: "error",
        confirmButtonColor: "#ffb133",
      })
    }
  }

  const setModalState = (name, open) => {
    console.log("dentro del set modal")
    switch(name){
      case "Add":
        setShowAdd(open)
        break;
      case "Reset":
        setShowReset(open)
        break;
      default:
        break;
    }
  }

  let permissions = JSON.parse(localStorage.getItem('permissions'));
    
  let permission = permissions.filter(perm => {
    if (perm.section === 'app_user') {
      return perm;
    }
  });

  return (
    <React.Fragment>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={menuState}
        onClose={handleRequestClose}
        MenuListProps={{
          style: {
            width: 150,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        {
          (permission != undefined && permission[0].can_update) &&
          <MenuItem onClick={() => {setModalState("Add", true)}}>
            <i className="zmdi zmdi-plus-circle" />
            <span className="ml-2">Cambiar grupo</span>
          </MenuItem>
        }
        {
          (permission != undefined && permission[0].can_update) &&
          <MenuItem onClick={() => {setModalState("Reset", true)}}>
            <i className="zmdi zmdi-border-color" />
            <span className="ml-2">Resetear</span>
          </MenuItem>
        }

      </Menu>

      {showAdd ? <Form 
        show={showAdd}
        title={"Cambiar grupo"}
        onSave={balanceAddition}
        readOnly={false}
        data={data}
        onClose={() => { setModalState("Add", false) }}
      /> : null } 

      {showReset ? <Reset
        show={showReset}
        title={"Resetear usuario"}
        onSave={resetAppUser}
        readOnly={false}
        data={data}
        onClose={() => { setModalState("Reset", false) }}
      /> : null }

    </React.Fragment>
  );
};

export default Options;
