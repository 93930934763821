// React
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { encryptData, decipherData } from "../../../../../services/api/Security.js"
import { makeStyles } from "@material-ui/core/styles";
// Material UI
import {
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import swal from "sweetalert";

// Services
import { get, post, update, destroy } from "../../../../../services/api/http.service.js";

const Form = (props) => {

  console.log("form super antes de todo ", props)
  const [form, setForm] = useState(props.data ? props.data : {status: false});
  const [trademarks, setTrademarks] = useState([]);
  const [isTrademarkRepeated, setIsTrademarkRepeated] = useState(false);

  console.log("form antes de todo ", form)

  const createData = (e) => {
    let inputTrademark = e.target.value.trim().toLowerCase();
    setIsTrademarkRepeated(false);
    for(let trademark of trademarks) {
  
      let currentTrademark = trademark.name.trim().toLowerCase();
      if(inputTrademark === currentTrademark) {
        setIsTrademarkRepeated(true);
      }
    }

    setForm({
      ...form,
      [e.target.name]: e.target.name != "status" ? e.target.value : e.target.checked 
    });
    console.log("Datos del form ", form)
  };

  const handleSummit = () => {
    props.onClose()
    props.onSave(form)
    setForm({ status: false });
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const tempData = await get("clients");
      setTrademarks(tempData.clients);
      
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Dialog
        open={props.show}
        TransitionComponent={Slide}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <TextField
            error={isTrademarkRepeated}
            autoFocus
            required
            size="small"
            margin="normal"
            id="name"
            label="Nombre"
            type="text"
            fullWidth
            name="name"
            value={form.name}
            onChange={createData}
            InputProps={{ readOnly: props.readOnly }}
            helperText={isTrademarkRepeated ? "Empresa Repetida" : ""}
          />
      
          <FormControlLabel
            control={
              <Checkbox
                checked={form.status}
                onChange={createData}
                name="status"
                color="primary"
                disabled={props.readOnly}
              />
            }
            label="Empresa activa"
            disabled={props.readOnly}
          />
         
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={props.onClose}
            color="primary"
            className="bg-danger text-white"
          >
            Cerrar
          </Button>
          <Button
              variant="contained"
              onClick={handleSummit}
              className={typeof form.name != "undefined" && form.name != "" && form.name.trim().length > 0 ? "bg-success text-white" : "bg-success.disabled text-white"} 
              disabled={typeof form.name == "undefined" ? true : false}
              hidden={props.readOnly || isTrademarkRepeated }
          > Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Form;
