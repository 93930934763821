import React, { useEffect, useState } from "react";
import MenuItem from '@material-ui/core/MenuItem';
import { get } from "../../../../../services/api/http.service";

// Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  FormControl,
  InputLabel
} from "@material-ui/core";

import Select from "@material-ui/core/Select";

const Form = (props) => {

  const [form, setForm] = useState(props.data ? props.data : {status: false});
  const [groups, setGroups] =  useState([]);
  const [selectedgroup, setselectedgroup] =  useState(0);

  useEffect(() => {
    getGroups();
  }, []);


  const getGroups = async () => {
    try {
      let response;
      if (localStorage.getItem("admin") == 1) {
        response = await get("groups")
      } else {
        response = await get("groups/client/" + localStorage.getItem("client_id"))
      }
      
      if (response.success) {
        const data = response.rows;
        setGroups(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSummit = () => {
    props.onClose()
    props.onSave(form, selectedgroup)
    setForm({ status: false });
  }

  return (
    <>
      <Dialog
        open={props.show}
        TransitionComponent={Slide}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>

          <FormControl fullWidth>
                      <InputLabel>grupo</InputLabel>
                      <Select
                        fullWidth
                        id="outlined-text-input"
                        label="Marca comercial"
                        type="text"
                        name="trademarks_id"
                        inputProps={{ maxLength: 128}}
                        value={selectedgroup}
                        onChange={e => setselectedgroup(e.target.value)}
                      >
                      { groups ? groups.map( (trademark) => {
                        return (<MenuItem value={trademark.id}> {trademark.name} </MenuItem>)
                      }) : null
                      }
                    </Select>

          </FormControl>
                  
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={props.onClose}
            color="primary"
            className="bg-danger text-white"
          >
            Cerrar
          </Button>
          <Button
              variant="contained"
              onClick={handleSummit}
              className={ selectedgroup == 0 ? "bg-success.disabled text-white" : "bg-success text-white" } 
              disabled= { selectedgroup == 0 }
              hidden={props.readOnly }
          > Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Form;