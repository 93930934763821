import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import fetchData from '../../../../../services/api/FetchData'
import swal from 'sweetalert'
import bodyParser from 'body-parser';
import ModalForm from "../Components/rewards_material"
import InstantModalForm from "../Components/Instant_wins"
import { post } from 'services/api/http.service';

const linkStyles = {
    textDecoration: 'none',
    color: "#000000"
}

const handleSubmitRewardsMaterials = async (body) => {
    try{
        body.rewards_id = parseInt(body.rewards_id)
        body.availableRewards = parseInt(body.availableRewards)
        let response = await post("rewards_material/reward/" + body.rewards_id, body)
        if(response.success){
            swal({
                title: "Éxito",
                text: response.message,
                icon: "success",
                confirmButtonColor: "#ffb133",
            })
        }
    }catch(err){
        console.log("Error: ", err)
        swal({
            title: "Error",
            text: err,
            icon: "error",
            confirmButtonColor: "#ffb133",
        })
    }
}

class Options extends React.Component {
    state = {
        showDialog: false,
        showReactive: false,
        showModal: false,
    }

    handleDialogClose() {
        this.setState({ ...this.state, showDialog: false });
    }

    handleReactiveClose() {
        this.setState({ ...this.state, showReactive: false });
    }

    async handleDelete() {
        this.props.handleRequestClose()
        this.handleDialogClose()
        const params = {
            method: "DELETE"
        }

        const response = await fetchData(`missions/${this.props.id}`,params)
        if(!response.success){
            swal({
                title: "Error",
                text: response.message,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
            return
        }
        swal({
            title: "Misión Desactivada",
            text: response.message,
            icon: "success",
            confirmButtonColor: "#ffb133",
        })
            //.then(()=>this.props.reloadData())
            //.then(()=>this.props.history.push("/app/missions"))
            .then(() => window.location.reload(false))
    }

    async handleReactive() {
        this.props.handleRequestClose()
        this.handleReactiveClose()

        let data = {
            active: true,
            statusId: 1
        }

        const params = {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
              },
        }
        console.log("params reactive", params);
        const response = await fetchData(`missions/reactive/${this.props.id}`,params)
        if(!response.success){
            swal({
                title: "Error",
                text: response.message,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
            return
        }
        swal({
            title: "Misión Reactivada",
            text: response.message,
            icon: "success",
            confirmButtonColor: "#ffb133",
        })
            //.then(()=>this.props.reloadData())
            //.then(()=>this.props.history.push("/app/missions"))
            .then(() => window.location.reload(false))
    }

    async handleSubmitRewardsMaterials(body){
        try{
            let response = await post("rewards_material/reward/" + body.rewards_id)
            if(response.success){
                swal({
                    title: "Éxito",
                    text: response.message,
                    icon: "success",
                    confirmButtonColor: "#ffb133",
                })
                .then(() => {
                    window.location.reload(false)
                })
            }
        }catch(err){
            console.log("Error: ", err)
            swal({
                title: "Error",
                text: err,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
        }
    }

    async handleSubmitRewardsInstantWins(body){
        try{
            const dataForm = new FormData();
            /*for (let key in body) {
              dataForm.append(key, body[key]);
            }*/
            dataForm.append("file", body.file);
            dataForm.append("rewards_id", body.rewards_id);
            //let response = await post("rewards_instant_wins/lote", dataForm)
            const options = {
                method: "POST",
                body: dataForm
              };
            let responseFetch = await fetchData("rewards_instant_wins/lote", options, true)
            let response = await responseFetch.json()
            if(response.success){
                swal({
                    title: "Éxito",
                    text: response.message,
                    icon: "success",
                    confirmButtonColor: "#ffb133",
                })
                /*swal("Éxito", response.message, "success").then(() => {
                    window.location.reload(false)
                })*/
            } else {
                swal({
                    title: "Error",
                    text: response.message,
                    icon: "error",
                    confirmButtonColor: "#ffb133",
                })
            }
        }catch(err){
            console.log("Error: ", err)
            swal({
                title: "Error",
                text: err,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
        }
    }


    render() {
        const { menuState, anchorEl, handleRequestClose, id, rewards_types_id } = this.props,
            viewLink = `/app/rewards/${id}`,
            editLink = `/app/rewards/${id}/edit`,
            rewards_material_link = `/app/rewards/${id}/rewards_material`

        return (
            <React.Fragment>
                <Dialog open={this.state.showDialog} TransitionComponent={Slide} onClose={this.handleDialogClose.bind(this)}>
                    <DialogTitle>
                        {"¡Está a punto de desactivar una misión!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>¿Deseas continuar?</DialogContentText>
                        <DialogContentText>
                            Si desactivas esta misión no podra ser elegible para los auditores.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose.bind(this)} color="secondary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDelete.bind(this)} color="primary">
                            Desactivar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showReactive} TransitionComponent={Slide} onClose={this.handleReactiveClose.bind(this)}>
                    <DialogTitle>
                        {"¡Está a punto de reactivar una misión!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>¿Deseas continuar?</DialogContentText>
                        <DialogContentText>
                            Si reactivas esta misión podra ser elegible para los auditores.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleReactiveClose.bind(this)} color="secondary" className="bg-danger text-white">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleReactive.bind(this)} color="primary" className="bg-success text-white">
                            Reactivar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Menu id="long-menu"
                    anchorEl={anchorEl}
                    open={menuState}
                    onClose={handleRequestClose}

                    MenuListProps={{
                        style: {
                            width: 200,
                            paddingTop: 0,
                            paddingBottom: 0
                        },
                    }}>
                    <Link style={linkStyles} to={viewLink}><MenuItem ><i className="zmdi zmdi-eye zmdi-hc-fw" /><span className="ml-2">Ver</span></MenuItem></Link>
                    <Link style={linkStyles} to={editLink}><MenuItem onClick={handleRequestClose}><i className="zmdi zmdi-edit zmdi-hc-fw" /><span className="ml-2">Editar</span></MenuItem></Link>
                    {(rewards_types_id == 1) &&
                        //<MenuItem onClick={() => this.setState({ ...this.state, showDialog: true })} style={{ color: "#ff0000" }}><i className="zmdi zmdi-delete zmdi-hc-fw" /><span className="ml-2">Desactivar</span></MenuItem>
                        <MenuItem onClick={() => this.setState({...this.state, showModal: true})}><i className="zmdi zmdi-hc-fw" /><span>Agregar instant wins</span></MenuItem>
                    }
                    {(rewards_types_id == 2) &&
                        <MenuItem onClick={() => this.setState({...this.state, showModal: true})}><i className="zmdi zmdi-hc-fw" /><span>Agregar premios materiales</span></MenuItem>
                    }
                </Menu>

                {rewards_types_id === 1 
                ?<InstantModalForm
                    show={this.state.showModal}
                    id={id}
                    //rewards_types_id={rewards_types_id}
                    onSubmit={this.handleSubmitRewardsInstantWins}
                    onClose={() => {
                        this.setState({...this.state,
                        showModal: false
                        })
                    }}                
                />
                : null}

                {rewards_types_id === 2
                ? <ModalForm 
                    show={this.state.showModal}
                    id={id}
                    //rewards_types_id={rewards_types_id}
                    onSubmit={handleSubmitRewardsMaterials}
                    onClose={() => {
                        this.setState({...this.state,
                        showModal: false
                        })
                    }}
                />
                : null}      

            </React.Fragment>
        );
    }

}

export default Options;