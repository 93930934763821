import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import List from "./routes/list"
import Create from "./routes/create"
import View from './routes/view'
import Edit from './routes/edit'
import Rewards_materials from './routes/rewards_material'

const Router = ({match}) => (
    <div className="app-wrapper">
    <Switch>
      <Route path={`${match.url}/create`} component={Create}/>
      <Route path={`${match.url}/:id`} component={View} ></Route>
      <Route path={`${match.url}/:id/edit`} component={Edit} ></Route>
      {/*<Route path={`${match.url}/:id/rewards_material`} component={Rewards_materials} ></Route>*/}
      <Route path={`${match.url}/`} component={List}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Router;