import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import fetchData from '../../../../../services/api/FetchData'
import swal from 'sweetalert'
import bodyParser from 'body-parser';

const linkStyles = {
    textDecoration: 'none',
    color: "#000000"
}

class Options extends React.Component {
    state = {
        showDialog: false,
        showReactive: false
    }

    handleDialogClose() {
        this.setState({ ...this.state, showDialog: false });
    }

    handleReactiveClose() {
        this.setState({ ...this.state, showReactive: false });
    }

    async handleDelete() {
        this.props.handleRequestClose()
        this.handleDialogClose()
        const params = {
            method: "DELETE"
        }

        const response = await fetchData(`missions/${this.props.id}`,params)
        if(!response.success){
            swal({
                title: "Error",
                text: response.message,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
            return
        }
        swal({
            title: "Misión Desactivada",
            text: response.message,
            icon: "success",
            confirmButtonColor: "#ffb133",
        })
            //.then(()=>this.props.reloadData())
            //.then(()=>this.props.history.push("/app/missions"))
            .then(() => window.location.reload(false))
    }

    async handleReactive() {
        this.props.handleRequestClose()
        this.handleReactiveClose()

        let data = {
            active: true,
            statusId: 1
        }

        const params = {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
              },
        }
        console.log("params reactive", params);
        const response = await fetchData(`missions/reactive/${this.props.id}`,params)
        if(!response.success){
            swal({
                title: "Error",
                text: response.message,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
            return
        }
        swal({
            title: "Misión Reactivada",
            text: response.message,
            icon: "success",
            confirmButtonColor: "#ffb133",
        })
            //.then(()=>this.props.reloadData())
            //.then(()=>this.props.history.push("/app/missions"))
            .then(() => window.location.reload(false))
    }

    render() {
        const { menuState, anchorEl, handleRequestClose, id, status } = this.props,
            viewLink = `fan_pages/${id}`,
            editLink = `fan_pages/${id}/edit`

        console.log("props options", this.props);

        return (
            <React.Fragment>
                <Dialog open={this.state.showDialog} TransitionComponent={Slide} onClose={this.handleDialogClose.bind(this)}>
                    <DialogTitle>
                        {"¡Está a punto de desactivar una misión!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>¿Deseas continuar?</DialogContentText>
                        <DialogContentText>
                            Si desactivas esta misión no podra ser elegible para los auditores.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose.bind(this)} color="secondary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleDelete.bind(this)} color="primary">
                            Desactivar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showReactive} TransitionComponent={Slide} onClose={this.handleReactiveClose.bind(this)}>
                    <DialogTitle>
                        {"¡Está a punto de reactivar una misión!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>¿Deseas continuar?</DialogContentText>
                        <DialogContentText>
                            Si reactivas esta misión podra ser elegible para los auditores.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleReactiveClose.bind(this)} color="secondary" className="bg-danger text-white">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleReactive.bind(this)} color="primary" className="bg-success text-white">
                            Reactivar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Menu id="long-menu"
                    anchorEl={anchorEl}
                    open={menuState}
                    onClose={handleRequestClose}

                    MenuListProps={{
                        style: {
                            width: 150,
                            paddingTop: 0,
                            paddingBottom: 0
                        },
                    }}>
                    <Link style={linkStyles} to={viewLink}><MenuItem ><i className="zmdi zmdi-eye zmdi-hc-fw" /><span className="ml-2">Ver</span></MenuItem></Link>
                    <Link style={linkStyles} to={editLink}><MenuItem onClick={handleRequestClose}><i className="zmdi zmdi-edit zmdi-hc-fw" /><span className="ml-2">Editar</span></MenuItem></Link>
                    {(status == 1) &&
                        <MenuItem onClick={() => this.setState({ ...this.state, showDialog: true })} style={{ color: "#ff0000" }}><i className="zmdi zmdi-delete zmdi-hc-fw" /><span className="ml-2">Desactivar</span></MenuItem>
                    }
                    {(status == 2) &&
                        <MenuItem onClick={() => this.setState({ ...this.state, showReactive: true })} style={{ color: "green" }}><i className="zmdi zmdi-spellcheck zmdi-hc-fw" /><span className="ml-2">Reactivar</span></MenuItem>
                    }
                </Menu>
            </React.Fragment>
        );
    }

}

export default Options;