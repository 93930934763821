import React, { Component } from "react";
import { Link } from 'react-router-dom'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Formik } from 'formik'
import * as Yup from 'yup'
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import swal from 'sweetalert';
import DateFnsUtils from '@date-io/date-fns';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { Typography, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { get, post, update, destroy } from "../../../../../services/api/http.service";

const requiredField = "Campo Requerido"
const positiveNumber = "Número debe ser positivo"

class DataDisplayer extends Component {

    constructor(props) {
        super(props)
        if (props.group) {
          const { group } = props
          this.state = { group }
          this.state = {
            group : {
              ...group
            }
          }
        } else {
            this.state = {
                group: {
                    name: "",
                    secretCode: "",
                    limitGroup: 0,
                    status: true
                }
            }   
        }
    }

    handleSubmit = this.props.onSubmit

    handleCancel = (values) => {
        this.props.onCancel(values)
    }

    render() {
        const { group } = this.state;
        
        return (
            <Formik
                initialValues={group}
                onSubmit={this.handleSubmit}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .required(requiredField),
                    secretCode: Yup.string()
                        .required(requiredField)
                        .max(10, "la longitud no debe ser mayor a 10"),
                    limitGroup: Yup.number()
                        .required(requiredField)
                        .positive(positiveNumber),
                    status: Yup.boolean()
                        .required(requiredField)
                })}
            >
                {
                    props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit
                        } = props
                        return (
                            <form className="container-fluid" autoComplete='off' onSubmit={handleSubmit}>
                                <div className="row mb-md-3">
                                    <div className="col-lg-6 col-sm-6 col-12">
                                        <TextField
                                            fullWidth
                                            id="outlined-text-input"
                                            //label="Nombre"
                                            label="Nombre"
                                            type="text"
                                            //name="name"
                                            name="name"
                                            inputProps={{ maxLength: 128, readOnly: this.props.readOnly }}
                                            onBlur={handleBlur}
                                            error={errors.name && touched.name}
                                            helperText={touched.name && (errors.name)}
                                            className={errors.name && touched.name && "error"}
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-sm-6 col-12">
                                        <TextField
                                            fullWidth
                                            id="outlined-text-input"
                                            //label="Nombre"
                                            label="Código de invitación"
                                            type="text"
                                            //name="name"
                                            name="secretCode"
                                            inputProps={{ maxLength: 128, readOnly: this.props.readOnly }}
                                            onBlur={handleBlur}
                                            error={errors.secretCode && touched.secretCode}
                                            helperText={touched.secretCode && (errors.secretCode)}
                                            className={errors.secretCode && touched.secretCode && "error"}
                                            value={values.secretCode}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-md-3">
                                    <div className="col-lg-6 col-sm-6 col-12">
                                        <InputLabel className="mt-3">Estatus</InputLabel>
                                        {this.props.readOnly ? (
                                            <Checkbox color="primary" checked={values.status} />
                                        ) : (
                                            <Checkbox
                                            color="primary"
                                            name="status"
                                            checked={values.status}
                                            onChange={handleChange}
                                            />
                                        )}
                                        {/* <FormControlLabel
                                            control={
                                            <Checkbox
                                                // checked={form.status}
                                                // onChange={createData}
                                                // name="status"
                                                // color="primary"
                                                // disabled={props.readOnly}
                                            />
                                            }
                                            label="Estatus"
                                            disabled={props.readOnly}
                                        /> */}
                                    </div>
                                    <div className="col-lg-6 col-sm-6 col-12">
                                        <TextField
                                            fullWidth
                                            id="outlined-text-input"
                                            //label="Nombre"
                                            label="Límite Inscritos"
                                            type="number"
                                            //name="name"
                                            name="limitGroup"
                                            inputProps={{ readOnly: this.props.readOnly }}
                                            onBlur={handleBlur}
                                            error={errors.limitGroup && touched.limitGroup}
                                            helperText={touched.limitGroup && (errors.limitGroup)}
                                            className={errors.limitGroup && touched.limitGroup && "error"}
                                            value={values.limitGroup}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                {
                                    this.props.readOnly &&
                                    <div className="row justify-content-center mt-4 pl-4">
                                    <Button className='btn mr-3' style={{ backgroundColor: '#0f2647', color: 'white' }} onClick={() => { this.handleCancel(values) }} >Regresar</Button>
                                    </div>
                                }
                                {
                                    (!this.props.readOnly && (<div className="row justify-content-center mt-4 pl-4">
                                    <Button disabled={isSubmitting} className='btn mr-3' style={{ backgroundColor: '#0f2647', color: 'white' }} onClick={() => { this.handleCancel(values) }} >
                                    Cancelar
                                                        </Button>
                                    <Button disabled={isSubmitting} className='btn' style={{ backgroundColor: '#ffb133', color: 'white' }} type="submit" >
                                    {isSubmitting && <span className="spinner-border spinner-border-sm"></span>}
                                                        Continuar
                                                        </Button>
                                    </div>))
                                }
                            </form>
                        )
                    }
                }   
            </Formik>
        );
    }
}

export default DataDisplayer;