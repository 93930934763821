import React from 'react'
import Form from '../Components/form'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import swal from 'sweetalert';
import { get, post, update, destroy } from "../../../../../services/api/http.service";

export default class Create extends React.Component {

    handleSubmit = async (values, groups) => {
        try{      
            let flag = groups.filter(row => row.isSelectedInReward == true)
            values.isForUsersWithoutGroup = flag.length > 0 ? false : true
            values.groups = groups
            const response = await post("rewards", values)
            if (!response.success) {
                swal({
                    title: "Error",
                    text: response.message,
                    icon: "error",
                    confirmButtonColor: "#ffb133",
                })
                return
            }
            swal({
                title: "Recompensa registrada",
                text: response.message,
                icon: "success",
                confirmButtonColor: "#ffb133",
            })
            .then(() => window.location.href = "/app/rewards")
        }catch(err){
            console.log("Error: ", err)
            swal({
                title: "Error",
                text: err,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
        }
    }

    handleCancel = () => this.props.history.push("/app/rewards")

    render() {        
        return (
            <Card className="container-fluid p-4 pl-5">
                <h1>Registrar recompensa</h1>
                <Divider className="mb-4" />
                <Card className="col-12 p-4 pl-5 mr-4">
                    <Form onCancel={this.handleCancel} onSubmit={this.handleSubmit} handleSubmit={this.handleSubmit} readOnly={false}/>
                </Card>
            </Card>
        )
    }
}