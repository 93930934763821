import React from 'react'
import Form from '../Components/form'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import { get, post, put, destroy } from "../../../../../services/api/http.service";
import swal from 'sweetalert';
import Loader from "../../../../../components/CircularProgress"
import { da } from 'date-fns/locale';

export default class Edit extends React.Component {

    state = {
        mission: null,
        trademarks: [],
        rewards: [],
        mission_types: [],
        status: [],
        fan_pages: []
    }
    
    getData = async (route, dataName) => {
        const response = await get(route)
        if (response.success) {
            this.setState({
                [dataName]: response.rows[0]
            })
        }
    }

    handleSubmit = async(values, groups, allGroups, withoutGroups, qr_image, question, question_details, coinsByQuestions) => {
        try{
            //poner time en 0 del datetime
            let data = Object.assign({},values)
            //data.date_transaction = values.date_transaction ? (new Date(values.date_transaction)).setHours(0,0,0) : values.date_transaction
            //data.deadline = values.deadline ? (new Date(values.deadline)).setHours(23,59,59) : values.deadline
            
            data.groups = groups
            //data.isWithoutGroup = withoutGroups ? 1 : (allGroups ? withoutGroups : )

            if(values.mission_types_id === 9) {
                data.is_social_network = false;
            } else {
                data.is_social_network = true;
            }
            data.qr_image = qr_image
            if(withoutGroups){
                data.isWithoutGroup = true
            } else {
                let findedAnyGroup = groups.filter(row => row.isSelectedInMission == true)
                if (allGroups || (typeof findedAnyGroup != "undefined" && findedAnyGroup != null && findedAnyGroup.length > 0) ){
                    data.isWithoutGroup = false
                } else {
                    data.isWithoutGroup = null
                }
            }

            data.question = question
            data.question_details = question_details
            data.coinsByQuestions = coinsByQuestions
            
            const response = await put(`missions/${values.id}`, data)
            if (!response.success) {
                swal({
                    title: "Error",
                    text: response.message,
                    icon: "error",
                    confirmButtonColor: "#ffb133",
                })
                return
            }
            swal({
                title: "Misión editada",
                text: response.message,
                icon: "success",
                confirmButtonColor: "#ffb133",
            })
            .then(() => this.handleCancel())
        }catch(err){
            swal({
                title: "Error",
                text: err,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
        }       
    }
    async getTrademarks(){
        try{
            let response = await get("trademarks")
            if(response.success){
                //trademarks = response.rows
                this.setState({
                    ...this.state,
                    trademarks: response.rows.filter(trademark => trademark.status === true) // solo marcas activas
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }

    async getFanPages() {
        try {
            let response = await get("fan_pages")
            if(response.success){
                this.setState({
                    ...this.state,
                    fan_pages: response.rows.filter(fan_page => fan_page.status === true) // solo fan_pages activas
                });
            } 
        } catch (err) {
            console.log("Error: ", err)
        }
    }

    async getRewards() {
        try{
            let response = await get("rewards");
            if(response.success){
                this.setState({
                    ...this.state,
                    rewards: response.rows
                });
            }
        } catch(err) {
            console.log("Error: ", err)
        }
    }

    async getMissionTypes(){
        try{
            let response = await get("mission_types")
            if(response.success){
                
                this.setState({
                    ...this.state,
                    mission_types: response.rows
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }

    async getStatus(){
        try{
            let response = await get("status")
            if(response.success){
                
                this.setState({
                    ...this.state,
                    status: response.rows.filter(status => status.status_typeId === 4) // Solo estatus de misiones 
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }

    async getSocialNetworks(){
        try {
            let response = await get("social_networks")
            if(response.success){
                this.setState({
                    ...this.state,
                    social_networks: response.rows.filter(trademark => trademark.status === true) // solo redes sociales activas
                });
            }
        } catch (err) {
            console.log("Error: ", err);
        }
    }

    async componentDidMount() {
        await this.getTrademarks()
        await this.getRewards()
        await this.getMissionTypes()
        await this.getStatus()
        await this.getFanPages()
        await this.getData(`missions/${this.props.match.params.missionId}`, "mission")
        await this.getSocialNetworks()
    }

    handleCancel = () => window.location.href = "/app/missions/"/*this.props.history.push("/app/missions")*/

    render() {
        return (
            this.state.mission ?
                <Card className="container-fluid p-4 pl-5">
                    <h1>Editar Misión</h1>
                    <Divider className="mb-4" />
                    <Card className="col-12 p-4 pl-5 mr-4">
                        <Form onCancel={this.handleCancel} onSubmit={this.handleSubmit}  handleSubmit={this.handleSubmit} trademarks={this.state.trademarks} rewards={this.state.rewards} fan_pages={this.state.fan_pages}
                            mission_types={this.state.mission_types} status={this.state.status} mission={this.state.mission} social_networks={this.state.social_networks} 
                            isSocialNetworkReadOnly={true}    
                        />
                    </Card>
                </Card>
                :
                <Loader />
        )
    }
}