import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { destroy } from "../../../../../services/api/http.service";
import swal from "sweetalert";

const Options = (props) => {
  const { menuState, anchorEl, handleRequestClose, id} = props;
  const [showEdit, setShowEdit] = useState(true)
  const [showView, setShowView] = useState(true)
  const [showDrop, setShowDrop] = useState(false)

  const handleDestroy = async () => {
    props.handleRequestClose();
    swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminado, no podrás recuperar este rol!",
      icon: "warning",
      buttons: ["Cancelar", "Sí, eliminar"],
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          try {
            const response = await destroy(`roles/${id}`);
            if (response.success) {
              swal({
                icon: "success",
                title: "Rol eliminado!",
                text: "El rol ha sido eliminado correctamente",
              });
            } else {
              swal("¡Algo salió mal!", {
                icon: "error",
                text: "Por favor, inténtelo de nuevo más tarde",
              });
            }
          } catch (error) {
            swal("¡Algo salió mal!", {
              icon: "error",
              text: "Por favor, inténtelo de nuevo más tarde",
            });
          }
        }
      });
  }

  return (
    <React.Fragment>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={menuState}
        onClose={handleRequestClose}
        MenuListProps={{
          style: {
            width: 150,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        {
          showView && (
            <Link to={`/app/roles/${id}`}>
              <MenuItem>
                <i className="zmdi zmdi-eye zmdi-hc-fw" />
                <span className="ml-2">Ver</span>
              </MenuItem>
            </Link>
          )
        }
        {
          showEdit && (
            <Link to={`/app/roles/${id}/edit`}>
              <MenuItem>
                <i className="zmdi zmdi-edit zmdi-hc-fw" />
                <span className="ml-2">Editar</span>
              </MenuItem>
            </Link>
          )
        }
        {
          showDrop && (
            <MenuItem onClick={handleDestroy}>
              <i className="zmdi zmdi-delete zmdi-hc-fw" />
              <span className="ml-2">Eliminar</span>
            </MenuItem>
          )
        }
      </Menu>
    </React.Fragment>
  );
};

export default Options;
