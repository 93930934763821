// React
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { encryptData, decipherData } from "../../../../../services/api/Security.js"

// Material UI
import {
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { Row, Col } from "reactstrap";

// Utils
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

// Services
import { get, post, update, destroy } from "../../../../../services/api/http.service";
import { first } from "@amcharts/amcharts4/.internal/core/utils/Array";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CreateUserForm = (props) => {
  const classes = useStyles();
  const [formUpdate, setFormUpdate] = useState([]);
  const [dataRoles, setDataRoles] = useState([]);
  const [stateActive, setStateActive] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [firstPassword, setFirstPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [clients, setClient] = useState([]);
  const [clientId, setClientId] = useState(null);

  const createDataUser = (e) => {
    setFormUpdate({
      ...formUpdate,
      [e.target.name]: e.target.value,
    });
  };  

  const checkFirstPassword = (e) => {
    setFirstPassword({
      [e.target.name]: e.target.value,
    });
    console.log("firstPassword", firstPassword);
  };
  const checkVerifyPassword = (e) => {
    setVerifyPassword({
      [e.target.name]: e.target.value,
    });
  };

  const getClients = async () => {
    try {
      const tempData = await get("clients");
      console.log(tempData);
      setClient(tempData.clients);
    } catch (err) {
      console.log(err);
    }
  };

  const isEnabled = () => {
    if (firstPassword == verifyPassword) {
      setButtonEnabled(true);
    }
  };

  const onSaveHandler = async () => {
      console.log("dataparams", formUpdate);
      props.onClose();
      const updateFormData = {
        ...formUpdate,
        roleId: roleId,
        statusId: stateActive == true ? 7 : 8,
        client_id: clientId
      };
      console.log("dataparams", updateFormData);
      try{
        console.log("dentro de")
        let chiperData = await encryptData(updateFormData)
        let bodyChiper = {data: chiperData}
        console.log("parametros a enviar", bodyChiper);
        let response = await post("users", bodyChiper)
        console.log("response ", response)
        if(response.success){
          swal({
            title: "Creación exitosa",
            text: "El usuario fue creado exitosamente.",
            icon: "success",
            confirmButtonColor: "#ffb133",
          })
          .then(() => {
            window.location.reload(false);
          });
        } else {
          swal({
            title: "Error",
            text: `Ups, hubo un problema \n ${response.message}`,
            icon: "error",
            confirmButtonColor: "#ffb133",
          })
        }
      }catch(err){
        swal({
          title: "Error",
          text: `Ups, hubo un problema \n ${err}`,
          icon: "error",
          confirmButtonColor: "#ffb133",
        })
      }
  };

  const handleUserActiveChange = (event) => {
    setStateActive(event.target.checked);
    //console.log("userActive", userActive);
    //console.log("Estado checkbox", stateActive);
  };

  useEffect(() => {
    const getDataRolesEffect = async () => {
      try {
        const tempRolesData = await get("roles");
        if(tempRolesData.success){
          setDataRoles(tempRolesData.rows);
        } else {
          console.log("Error: ", tempRolesData.message)
        }
      } catch (err) {
        console.log(err);
      }
    };
    getDataRolesEffect();
    getClients();
  }, []);

  return (
    <>
      <Dialog
        open={props.show}
        TransitionComponent={Slide}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Crear Usuario</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            size="small"
            margin="normal"
            id="username"
            label="Nombre"
            type="text"
            fullWidth
            name="username"
            onChange={createDataUser}
          />
          <TextField
            autoFocus
            required
            size="small"
            margin="dense"
            id="mail"
            label="Email"
            type="email"
            fullWidth
            name="email"
            onChange={createDataUser}
          />

          <TextField
            autoFocus
            required
            size="small"
            margin="dense"
            id="firstPassword"
            label="Contraseña"
            type="password"
            fullWidth
            name="firstPassword"
            onChange={(e) => {
              setFirstPassword(e.target.value);
              //console.log(e.target.value);
            }}
          />
          <TextField
            error={(firstPassword == verifyPassword ) ? false : true }
            helperText={ (firstPassword == verifyPassword) ? "" :"Las contraseñas no coinciden"}
            autoFocus
            required
            size="small"
            margin="dense"
            id="contraseñaConfirmación"
            label="Confirmar contraseña"
            type="password"
            fullWidth
            name="password"
            onChange={(e) => {
              setVerifyPassword(e.target.value);
              createDataUser(e);
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stateActive}
                onChange={handleUserActiveChange}
                name="active"
                color="primary"
              />
            }
            label="Usuario Activo"
          />
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Rol
            </InputLabel>
            <Select
              onChange={(e) => {
                setRoleId(e.target.value);
              }}
            >
              {!(typeof dataRoles == "undefined") &&
                dataRoles.map((role) => {
                  return (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {
            localStorage.getItem("admin") == 1 &&
            <FormControl className={classes.formControl}>
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Cliente
              </InputLabel>
              <Select
                onChange={(e) => {
                  setClientId(e.target.value);
                }}
              >
                {!(typeof clients == "undefined") &&
                  clients.map((client) => {
                    return (
                      <MenuItem key={client.id} value={client.id}>
                        {client.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          }
          
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={props.onClose}
            className="bg-danger text-white"
          >
            Cerrar
          </Button>
          {firstPassword &&
          verifyPassword &&
          roleId &&
          stateActive &&
          ((firstPassword == verifyPassword) && (firstPassword != "" && verifyPassword != "")) ? (
            <Button
              variant="contained"
              onClick={onSaveHandler}
              color="primary"
              className="bg-success text-white"
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={onSaveHandler}
              style={{
                backgroundColor: "#C0C0C0",
            }}
              disabled
              className="text-white"
            >
              Guardar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateUserForm;
