import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import List from "./routes/list"
import Create from "./routes/create"
import View from './routes/view'
import Edit from './routes/edit'

const Router = ({match}) => (
    <div className="app-wrapper">
      <Switch>
        <Route path={`${match.url}/:missionId/edit`} component={Edit} />
        <Route path={`${match.url}/create`} component={Create}/>
        <Route exact path={`${match.url}`} component={List}/>
        <Route path={`${match.url}/:missionId`} component={View} />
        <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
      </Switch>
    </div>
);

export default Router;