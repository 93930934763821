import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import swal from "sweetalert";

import Form from '../components/form'
import { get, put } from "../../../../../services/api/http.service";

import { useParams } from 'react-router-dom';

const Edit = () => {
    const { id } = useParams();
    const [role, setRole] = useState(null)

    const handleSubmit = async (values) => {
        values.user_id = localStorage.getItem("ID");
        try {
            const response = await put(`roles/${role.id}`, values);

            swal({
                title: "Rol actualizado",
                text: "El rol se ha actualizado correctamente",
                icon: "success",
                confirmButtonColor: "#ffb133",
            })
            .then(() => window.location.href = "/app/roles/")
        } catch (error) {
            swal({
                title: "Error",
                text: 'Ah ocurrido un error al intentar actualizar el rol, por favor intenta de nuevo',
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
        }
    }

    const fetchData = async () => {
        const response = await get(`roles/${id}`);
        if (response.success) {
            let rol = response.rows;
            let permissions = rol.role_permissions;
            let defaultRoles =[
                {
                    label: 'Roles',
                    section: 'roles',
                    can_write: false,
                    can_read: false,
                    can_update: false,
                    can_delete: false
                  },
                  {
                    label: 'Equipo',
                    section: 'equipo',
                    can_write: false,
                    can_read: false,
                    can_update: false,
                    can_delete: false
                  },
                  {
                    label: 'Marcas/Empresas',
                    section: 'client',
                    can_write: false,
                    can_read: false,
                    can_update: false,
                    can_delete: false
                  },
                  {
                    label: 'Grupo',
                    section: 'group',
                    can_write: false,
                    can_read: false,
                    can_update: false,
                    can_delete: false
                  },
                  {
                    label: 'Usuarios de app',
                    section: 'app_user',
                    can_write: false,
                    can_read: false,
                    can_update: false,
                    can_delete: false
                  },
                  {
                    label: 'Puntaje',
                    section: 'refund',
                    can_write: false,
                    can_read: false,
                    can_update: false,
                    can_delete: false
                  },
                  {
                    label: 'Misiones',
                    section: 'mission',
                    can_write: false,
                    can_read: false,
                    can_update: false,
                    can_delete: false
                  },
                  {
                    label: 'Notificaciones',
                    section: 'notification',
                    can_write: false,
                    can_read: false,
                    can_update: false,
                    can_delete: false
                  }
            ];

            defaultRoles.forEach((section) => {                
                let permission = permissions.find((p) => p.section === section.section);
                if (permission === undefined) {
                    permissions.push(section);
                }
            });

            rol.role_permissions = permissions;
            setRole(rol);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        role === null || role === undefined ? 
        <Card className="container-fluid p-4 pl-5"></Card> :
        <Card className="container-fluid p-4 pl-5">
            <div className='d-flex m-4 align-middle'>
                <h1>Actualiza el rol</h1>
            </div>
            <div className='ml-5 mr-5'>
                <span className='normalText'>
                    Personaliza los accesos y funciones de tu equipo con facilidad. Crea roles específicos que se ajusten a cada tarea y necesidad del negocio, 
                    optimizando el flujo de trabajo y manteniendo segura la información. Ideal para ajustar responsabilidades y mejorar la productividad.
                </span>
                
                <Form onHandleSubmit={handleSubmit} data={role} isUpdate={true}/>
            </div>
        </Card>
    );
}

export default Edit;