import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import { TableRow, TableCell, Button, TextField } from "@material-ui/core";

import Options from "./options";

const RowTable = props => {
  const { isItemSelected, row } = props;
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [menuState, setMenuState] = useState(false);

  console.log("los props de row table ", props)

  const openMenuOptions = event => {
    setMenuState(true);
    setAnchorEl(event.currentTarget);
  };
  const closeMenuOptions = () => {
    setMenuState(false);
  };
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      <TableCell align="left">{row.name || "No registrado"}</TableCell>
      <TableCell align="left">{row.email || "No registrado"}</TableCell>
      <TableCell align="left">{row.balance }</TableCell>
      <TableCell align="right">
        <IconButton onClick={openMenuOptions}>
          <MoreVert />
        </IconButton>
        <Options
          menuState={menuState}
          anchorEl={anchorEl}
          handleRequestClose={closeMenuOptions}
          data={row}
        />
      </TableCell>
    </TableRow>
  );
};

export default RowTable;

