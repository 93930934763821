import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import List from "./routes/list"

const Router = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Route path={`${match.url}/`} component={List}/>
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Router;