import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { get, post, put, destroy } from "../../../../../services/api/http.service";
import Form from "../components/form"
import { da } from "date-fns/locale";

const Options = (props) => {
  const { menuState, anchorEl, handleRequestClose, data} = props;
  const [showEdit, setShowEdit] = useState(false)
  const [showView, setShowView] = useState(false)

  console.log("data antes de todo ", data)

 
  const update = async (form) => {
    console.log("Register params: ", form);
    console.log("A donde apunta: ", "trademarks/" + data.id, " ", data);
    const body = {
      ...form,
    };
    //eliminar id del body para no afectar registro
    delete body["id"]
    try{
      let response = await put("trademarks/" + data.id, body)
      if(response.success){
        swal({
          title: "Actualización exitosa",
          text: response.message,
          icon: "success",
          confirmButtonColor: "#ffb133",
        })
        .then(() => {
          //getData()
          //window.location.reload(false);
          //window.location.reload()
          window.location.href = window.location.href;
        });
      } else {
        swal({
          title: "Error",
          text: `Ups, hubo un problema \n ${response.message}`,
          icon: "error",
          confirmButtonColor: "#ffb133",
        })
      }
    }catch(err){
      swal({
        title: "Error",
        text: `Ups, hubo un problema \n ${err}`,
        icon: "error",
        confirmButtonColor: "#ffb133",
      })
    }
  };

  const setModalState = (name, open) => {
    console.log("dentro del set modal")
    switch(name){
      case "Edit":
        setShowEdit(open)
        break;
      case "View":
        setShowView(open)
        break;
      default:
        break;
    }
  }

  return (
    <React.Fragment>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={menuState}
        onClose={handleRequestClose}
        MenuListProps={{
          style: {
            width: 150,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <MenuItem onClick={() => {setModalState("View", true)}}>
          <i className="zmdi zmdi-eye zmdi-hc-fw" />
          <span className="ml-2">Ver</span>
        </MenuItem>
        <MenuItem onClick={() => {setModalState("Edit", true)}}>
          <i className="zmdi zmdi-edit zmdi-hc-fw" />
          <span className="ml-2">Editar</span>
        </MenuItem> 
      </Menu>

      {/* Edit */}
      {console.log("data pasada en edit ", data)}
      {showEdit ? <Form 
        show={showEdit}
        title={"Editar cliente comercial"}
        onSave={update}
        readOnly={false}
        data={data}
        onClose={() => { setModalState("Edit", false) }}
      /> : null } 
      {/* View */}
      {showView ? <Form 
        show={showView}
        title={"Ver cliente comercial"}
        readOnly={true}
        data={data}
        onClose={() => { setModalState("View", false) }}
      /> : null}


    </React.Fragment>
  );
};

export default Options;
