import React, {useEffect} from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider} from '@material-ui/pickers';
import { Redirect, Route, Switch } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
import AppLocale from "../lngProvider";

import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from "constants/ThemeColors";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import RecoveryPassword from "./RecoveryPassword"
import { setInitUrl } from "../actions/Auth";
import RTL from "util/RTL";
import asyncComponent from "util/asyncComponent";
import { setDarkTheme, setThemeColor } from "../actions/Setting";
import AppLayout from "./AppLayout";

const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;

const App = (props) => {
  const dispatch = useDispatch();
  const {themeColor, darkTheme, locale, isDirectionRTL} = useSelector(({settings}) => settings);
  const {authUser, initURL} = useSelector(({auth}) => auth);
  //const initURL = "";
  //const authUser = localStorage.getItem('authUser')
  const isDarkTheme = darkTheme;
  const {match, location} = props;

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.pathname));
    }
    const params = new URLSearchParams(props.location.search);
    if (params.has("theme-name")) {
      dispatch(setThemeColor(params.get('theme-name')));
    }
    if (params.has("dark-theme")) {
      dispatch(setDarkTheme());
    }
  }, [dispatch, initURL, props.history.location.pathname, props.location.search]);


  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme({...indigoTheme, direction: 'rtl'});
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default : createMuiTheme(indigoTheme);
    }
    return applyTheme;
  };

  let applyTheme = createMuiTheme(indigoTheme);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createMuiTheme(darkTheme)
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }
  //console.log("+++++++++++++*** url ", initURL);
  //console.log("+++++++++++++*** auth ", typeof authUser);
  //console.log("+++++++++++++*** auth ", authUser);

  /*
  if (location.pathname === '/') {
    console.log("location pathn name \n\n\n\n ", location.pathname)
    if (authUser === null ) {
      console.log("rediridigo a signin \n\n\n\n", authUser)
      return ( <Redirect to={'/signin'}/> );
    } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
      console.log("print de authUser \n\n\n\n", authUser)
      console.log("rediridigo a dashboard \n\n\n\n", initURL)
      return ( <Redirect to={'/app/dashboard/system'}/> );
    } else {
      console.log("print de authUser en else desconocido \n\n\n\n", authUser)
      console.log("rediridigido a init url en else desconocido \n\n\n\n", initURL)
      //return ( <Redirect to={initURL}/> );
      return <Redirect to={'/'}/>
    }
  }
  */

 if (location.pathname === '/') {
  if (authUser === null) {
    //console.log("Caso 1")
    return (<Redirect to={'/signin'} />);
  } else if (authUser === undefined){
    //console.log("Caso 2")
    return (<Redirect to={'/signin'} />);
  } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
    //console.log("Caso 3")
    return (<Redirect to={'/app/dashboard/system'} />);    
  }else {
    //console.log("Caso 4")
    return (<Redirect to={initURL} />);
  }
}


  if (isDirectionRTL) {
    applyTheme.direction = 'rtl';
    document.body.classList.add('rtl')
  } else {
    document.body.classList.remove('rtl');
    applyTheme.direction = 'ltr';
  }

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <RTL>
            <div className="app-main">
              <Switch>
                <RestrictedRoute path={`${match.url}app`} authUser={authUser}
                                 component={AppLayout}/>
                <Route path='/signin' component={SignIn}/>
                <Route path='/signup' component={SignUp}/>
                <Route path='/password/recovery/:token' component={RecoveryPassword}/>
                <Route path={`/login`}
                 component={asyncComponent(() => import("./login"))}/>  
                <Route
                  component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;


//========================================================================================================== smart abajo
/*

import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl'
import "assets/vendors/style"
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';

import MainApp from '../app/routes/index';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { setInitUrl } from '../actions/Auth';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import fetchData from "../services/api/FetchData"
import storageManage from "../services/storage"
import swal from "sweetalert"
import auth from "../sagas/Auth"
const jwt = require("jsonwebtoken");
const crypto = require("crypto-js");
const bodyParser = require("body-parser");

const RestrictedRoute = ({ component: Component, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location }
          }}
        />}
  />;

const LoginComponent = ({ component: Component, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/app/home',
            state: { from: props.location }
          }}
        />}
  />;

class App extends Component {

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  handleLogin = async (values) => {
        ///////////////////////////////////////////////////////////////////////
    let password = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5h"
    const encrypt = function(text) {
      return crypto.AES.encrypt(text, password).toString();
    };
    console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm")
    let keyAuth = "uVunBskhTMqYGGgL6prFzDqtxijGpvUn1bKpZhJU79o1Jprr5hUJ6TD";
    let bodyAuth = {key: keyAuth};
    let stringData = JSON.stringify(bodyAuth);
    var cipherText = encrypt(stringData);
    console.log("********************* cipherText ", cipherText);

    const paramsAuth = {
      method: "POST",
      body: JSON.stringify(bodyAuth),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm authentication params\n", paramsAuth)
    const authentication = await fetchData("authentication", paramsAuth);
    console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm authentication \n", authentication)
    let tokenAuth = authentication.token;
    let dataToSend = Object.assign(values, {access_token: tokenAuth});
       //////////////////////////////////////////////////////////////////////////////////

    const params = {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const response = await fetchData("login/CRM", params);
    if (response.success) {      
     // const { token, roleAndPermissions: { role, permissions } } = response
     console.log("********************************* toke exitoso \n\n\n ", response.token);
      const  token  = response.token
      //storageManage.setStorage({ token: { access_token: token, role, permissions } })
      storageManage.setStorage({ token: { access_token: token } })
      let data = JSON.parse(localStorage.getItem("data"));
      console.log("********************* kkkkkkkkkkkkkkkkkkkkkk ", response.token);
      //window.location.reload()
      this.props.history.push("/")
    }
    else {
      swal("Error", "Usuario y/o contraseña incorrectos", "error")
    }
  }

  render() {
    const { match, location, locale, authUser, initURL, isDirectionRTL } = this.props;
    const token = storageManage.getToken();
    console.log("********************* ", location, "\n\n");
    console.log("********************* ", token);
    if (location.pathname === '/') {
      if (token === null) {
        return (<Redirect to={'/signin'} />);
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return (<Redirect to={'app/dashboard/system'} />);
      } else {
        return (<Redirect to={initURL} />);
      }
    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute path={`${match.url}app`} authUser={token}
                    component={MainApp} />
                  <LoginComponent path={`${match.url}signin`} authUser={!token}
                    component={props => <SignIn {...props} onLogin={this.handleLogin} />} />
                  <Route path='/signin' component={props => <SignIn {...props} onLogin={this.handleLogin} />} />
                  <Route path='/signup' component={SignUp} />
                  <Route
                    //component={asyncComponent(() => import('components/Error404'))} 
                    />
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { authUser, initURL } = auth;
  return { sideNavColor, locale, isDirectionRTL, authUser, initURL }
};

export default connect(mapStateToProps, { setInitUrl })(App);

*/




/*
import React, {useEffect} from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider} from '@material-ui/pickers';
import { Redirect, Route, Switch } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
import AppLocale from "../lngProvider";

import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from "constants/ThemeColors";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { setInitUrl } from "../actions/Auth";
import RTL from "util/RTL";
import asyncComponent from "util/asyncComponent";
import { setDarkTheme, setThemeColor } from "../actions/Setting";
import AppLayout from "./AppLayout";

const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;

const App = (props) => {
  const dispatch = useDispatch();
  const {themeColor, darkTheme, locale, isDirectionRTL} = useSelector(({settings}) => settings);
  const {authUser, initURL} = useSelector(({auth}) => auth);
  const isDarkTheme = darkTheme;
  const {match, location} = props;

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.pathname));
    }
    const params = new URLSearchParams(props.location.search);
    if (params.has("theme-name")) {
      dispatch(setThemeColor(params.get('theme-name')));
    }
    if (params.has("dark-theme")) {
      dispatch(setDarkTheme());
    }
  }, [dispatch, initURL, props.history.location.pathname, props.location.search]);


  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme({...indigoTheme, direction: 'rtl'});
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default : createMuiTheme(indigoTheme);
    }
    return applyTheme;
  };

  let applyTheme = createMuiTheme(indigoTheme);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createMuiTheme(darkTheme)
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }
  if (location.pathname === '/') {
    if (authUser === null) {
      return ( <Redirect to={'/signin'}/> );
    } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
      return ( <Redirect to={'/app/dashboard/crypto'}/> );
    } else {
      return ( <Redirect to={initURL}/> );
    }
  }
  if (isDirectionRTL) {
    applyTheme.direction = 'rtl';
    document.body.classList.add('rtl')
  } else {
    document.body.classList.remove('rtl');
    applyTheme.direction = 'ltr';
  }

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <RTL>
            <div className="app-main">
              <Switch>
                <RestrictedRoute path={`${match.url}app`} authUser={authUser}
                                 component={AppLayout}/>
                <Route path='/signin' component={SignIn}/>
                <Route path='/signup' component={SignUp}/>
                <Route
                  component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
*/